<template>
    <div>
        <!-- Cart Drawer here -->

        <!-- Page Container here  -->
        <div id="page-container">

            <div class="main-container">
                <div class="ml-3 mb-5" style="height:40px">
                    <img src="/img/logo_full.png" alt="" height="100%">
                </div>

                <div class=" mt-3 row center-container justify-content-sm-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4 mb-4">
                                    <div class="col-12 col-md-7 align-self-center">
                                        <!-- <h1>Oops!</h1> -->
                                        <h2>Under Maintenance</h2>
                                        <p style="font-size:25px">We will be back soon.</p>
                                    </div>
                                    <div class="col-12 col-md-4 text-left">
                                        <img src="/img/maintenance.png" alt="" width="80%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        
    }
}
</script>

<style>
.main-container {
    padding: 20px 0px;
}

.main-container .container {
    /* font-family: "Karla",arial,sans-serif; */
    font-size: 16px;
}

.main-container .container h1 {
    letter-spacing: 0.2em;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 1.71429em;
}

.center-container {
    /* padding: 0 150px; */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.card {
    border: unset;
}

h1 {
    font-size: 6.0rem;
}
h2 {
    font-size: 2.5rem;
}
</style>